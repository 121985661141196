import React from "react";
import book5 from "../assets/images/capital.jpg";
import book6 from "../assets/images/english8.jpg";
import book7 from "../assets/images/cursive2.jpg";
import book8 from "../assets/images/colourc.jpg";
import "./Content.css";
import book2 from "../assets/images/book2.png";



export const Content = () => {
  return (
    <div className=" bg-[#fefce8]">
    <div className="container mx-auto my-4">
      <h4 className="py-5">Latest From Bhavya Publications Blog</h4>
      <div className="grid grid-cols-3 gap-4 mb-5" id="slide">
        <img className="" src={book2}></img>
        <img className="" src={book2}></img>
        <img className="" src={book2}></img>
      </div>
      </div>
      <hr className="py-9"/>
      <h4 className="py-9">Our Top FlipBooks For 2024</h4>
      <div className="grid grid-cols-4 gap-4" id="slide">
      <img className="" src={book5}></img>
        <img className="" src={book6}></img>
        <img className="" src={book7}></img>
        <img className="" src={book8}></img>
    </div>
    </div>
  );
};
