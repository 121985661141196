import React from "react";
import InnerGrid from "../../Category/InnerGrid";
import ClassCard from "../../components/ClassCard";
import Class3Data from "./Class3.json";

const classNameForPdf = "Class3";

const Class3 = () => {
  return (
    <>
      <div className="category-books-section pt-9 md:pt-20  h-fit bg-[#f3f3f3]">
        <div className="container w-[90%] px-[15px] mx-auto before:content-[''] after:content-['']">
          <div className="row before:content-[''] after:content-[''] mx-[-15px] w-full ">
            {/* pghead */}
            <div className="w-full">
              <h3 className="pghead text-center mb-[20px] font-sans font-bold uppercase text-2xl">
                CLass 3rd-Books
              </h3>
            </div>
            <div className="nursery-books flex columns-4 flex-wrap gap-3 mx-auto">
              {Class3Data.map((Class3, id) => {
                return (
                  <ClassCard
                    key={id}
                    classNameForPdf={classNameForPdf}
                    bookname={Class3.BookName}
                    url={Class3.BookNameurl}
                    fileId={Class3.fileId}
                    ebookUrl={Class3.ebookUrl}
                    image={Class3.image}
                  />
                );
              })}
            </div>
            {/* innergrid */}
            <InnerGrid />
          </div>
        </div>
      </div>
    </>
  );
};

export default Class3;
