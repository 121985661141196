import React from 'react';
import './tailwind.css'
import image from '../assets/images/computer1.jpg';
import image1 from '../assets/images/maths4.jpg';
import image2 from '../assets/images/capital.jpg';
import image3 from '../assets/images/cursive5.jpg';
import image4 from '../assets/images/english1.jpg';
import image5 from '../assets/images/evs1.jpg';
import image6 from '../assets/images/maths1.jpg';
import image7 from '../assets/images/science1.jpg';
import image8 from '../assets/images/sst3.jpg';
import image9 from '../assets/images/maths4.jpg';
import image10 from '../assets/images/numbera.jpg';
import image11 from '../assets/images/evs4.jpg';

const SlidingImageAnimation = () => {
  return (
    <div className="flex items-center overflow-hidden">
      <div className="flex animation-slide">
        <img src={image1} alt="Image 1" className="w-48 h-auto mr-4" />
        <img src={image} alt="Image 2" className="w-48 h-auto mr-4" />
        <img src={image3} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image4} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image5} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image6} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image7} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image8} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image9} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image10} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image11} alt="Image 3" className="w-48 h-auto mr-4" />
        <img src={image2} alt="Image 3" className="w-48 h-auto mr-4" />


        {/* Add more images as needed */}
      </div>
    </div>
  );
};

export default SlidingImageAnimation;
